<template>
  <v-hover v-slot="{hover}" >

    <v-sheet color="transparent" class="wsRoundedLight relative" >

        <v-sheet color="transparent" :key="'content' + refresh"  >

          <draggable v-model="content"
                     :empty-insert-threshold="30"
                     :forceFallback="true"
                     group="editor-section"
                     handle=".editor-section-drag"
                     tag="div"
                     v-bind="opt"
                     @start="drag = true"
                     :key="'topic_index_drag' +1"
                     @change="editContent()"
                     @end=" drag = false"
          >

          <div v-for="(section, i) in content" :key="'section' + i +refresh" >

            <editor-section-html
                v-if="section.isHtml && !avalonStyle"
                :placeholder="placeholder"
                @input="editContent"
                @edit="editSectionAction($event,i)"
                :section="section"
                :key="'html_' + i "

            />

            <editor-section-avalon-html
                v-if="section.isHtml && avalonStyle"
                :placeholder="placeholder"
                @input="editContent"
                @edit="editSectionAction($event,i)"
                :section="section"
                :key="'html_' + i "
                :avalon-style="avalonStyle"
            />

            <editor-section-file
                v-if="section.isFile"
                @input="editContent"
                @edit="editSectionAction($event,i)"
                :section="section"
                :component-id="componentId"
                :isPublic="isPublic"
                :superadmin="superadmin"
                :key="'file_' + i "/>

            <editor-section-video
                v-if="section.isVideo"
                @input="editContent"
                @edit="editSectionAction($event,i)"
                :section="section"
                :component-id="componentId"
                :question-id="questionId"
                :isPublic="isPublic"
                :superadmin="superadmin"
                :key="'video_' + componentId + i + refresh "/>

            <editor-section-image
                v-if="section.isImage"
                @input="editContent"
                @edit="editSectionAction($event,i)"
                :section="section"
                :isPublic="isPublic"
                :superadmin="superadmin"
                :key="'image_' + i " />

          </div>

          </draggable>
          <h5 v-if="content.length === 0" style="color : #888888"> {{ placeholder || $t('EnterText') }}</h5>

        </v-sheet>

      <!-- Add Item Button-->
      <v-fade-transition>
        <v-sheet
            outlined
            v-if="hover || edit || editDropDownOn"
            @mouseleave="displayAddButtons = false"
            color="transparent"
            class="addButtonsStyle"
            height="36">
          <div v-if="hover || edit || editDropDownOn" style="position: relative">
            <v-divider   style="border-style: dashed; "  class="mt-4" />
            <div class="d-flex justify-center" style="margin-top: -22px">
              <v-btn @mouseenter="expandAddButton" large
                     icon :color="wsATTENTION"><v-icon large>mdi-plus-circle</v-icon> </v-btn>
              <v-expand-x-transition>
                <v-sheet v-show="displayAddButtons"  color="transparent"   >
                  <div class="d-flex" >
                    <template v-for="(item,i) in addItemsSelect" >
                      <ws-icon-button
                          @click="addItemsAction(item.value)"
                          :icon="item.icon" circle
                          :tooltip="item.text"
                          :key="'addItemsButtons' + i"/>
                    </template>
                  </div>
                </v-sheet>
              </v-expand-x-transition>

            </div>
          </div>
        </v-sheet>
      </v-fade-transition>


    </v-sheet>
  </v-hover>
</template>

<script>

import { mapState } from "vuex";

import editorSectionFile from "@/components/UI/textEditor/editorSectionFile";
import editorSectionHtml from "@/components/UI/textEditor/editorSectionHtml";
import editorSectionAvalonHtml from "@/components/UI/textEditor/editorSectionAvalonHtml.vue";
import editorSectionImage from "@/components/UI/textEditor/editorSectionImage";
import editorSectionVideo from "@/components/UI/textEditor/editorSectionVideo";
import draggable from "vuedraggable";

export default {
  name: "wsTextEditor",
  props : {
    value : {
      type : Array
    },
    html : {
      type : String
    },
    noVideo : {
      type : Boolean,
      default : false
    },
    noFile : {
      type : Boolean,
      default : false
    },
    placeholder : {
      type : String
    },
    componentId : {
      type : String,
    },
    questionId : {
      type : String
    },
    isPublic : {
      type : Boolean,
      default : false
    },
    superadmin : {
      type : Boolean,
      default : false
    },
    avalonStyle : {
      type : String,
    }
  },
  components : {
    editorSectionFile,
    editorSectionHtml,
    editorSectionAvalonHtml,
    editorSectionImage,
    editorSectionVideo,
    draggable,
  },
  data() {
    return {

      opt : {
        animation: 200,
        ghostClass: "ghost"
      },
      drag : false,

      content : [],
      refresh : 0,
      displayAddButtons : false,
      edit : false,
      editDropDownOn : false,
    }
  },
  computed : {
    ...mapState('courses', [ 'selectedLang']),

    addItemsSelect() {
      let items = [
        { text : this.$t('Text')   , value : 'htmlSection'  , icon : 'mdi-text' },
        { text : this.$t('Image')  , value : 'imageSection' , icon : 'mdi-folder-multiple-image' },
      ]

      if ( !this.noVideo ) {
        items.push( { text : this.$t('Video')  , value : 'videoSection' , icon : 'mdi-video' } )
      }
      if ( !this.noFile ) {
        items.push( { text : this.$t('File')   , value : 'fileSection'  , icon : 'mdi-paperclip' } )
      }

      return items
    },
    // section types
    htmlSection() {
      return {
        isHtml : true,
        content : ''
      }
    },
    imageSection() {
      return {
        isImage : true,
        url : ''
      }
    },
    videoSection() {
      return {
        isVideo : true,
        isUploaded : true,
        isEmbed : false,
        file : null,
        embed : null
      }
    },
    fileSection() {
      return {
        isFile : true,
        files : [],
      }
    },

  },
  watch : {
    value() {
      if ( this.value !== this.content ) {
        this.refresh ++ ;
        this.initContent()
      }
    },
  },
  methods : {

    addItemsAction(type) {
      let newItem = JSON.parse(JSON.stringify(this[type]))

      this.content.push(newItem)
      this.editContent()
      this.refresh++
    },
    async editContent() {
      this.$emit('input', this.content )
      this.$emit('change', this.content )
    },

    editSectionAction(action , index ) {
      let buffer = JSON.parse(JSON.stringify(this.content))
      this.content  = []
      if ( action === 'delete' ) {
        buffer =  buffer.filter( (el,i) => i !== index)
      }
      if ( action === 'duplicate' ) {
        let duplicatedSection = Object.assign({},buffer[index])
        buffer.splice(index + 1, 0 , duplicatedSection)
      }
      setTimeout(() => {
        this.content = JSON.parse(JSON.stringify(buffer))

        if ( this.content.length === 0 ) {
          this.content.push(JSON.parse(JSON.stringify(this.htmlSection)))
        }

        this.editContent()
      },1)


    },

    // technical
    expandAddButton() {
      this.displayAddButtons = true
    },

    initContent() {
      if ( this.value ) {
        this.content = JSON.parse(JSON.stringify(this.value))
        if ( this.content.length === 0 && !this.html ) {
          this.content.push(JSON.parse(JSON.stringify(this.htmlSection)))
        }

        if ( this.content.length === 0 && this.html ) {
          let htmlSection = JSON.parse(JSON.stringify(this.htmlSection))
          htmlSection.text = this.html
          this.content.push(htmlSection)
          this.editContent()
        }

      } else {
        if ( this.content.length === 0 && this.html ) {
          let htmlSection = JSON.parse(JSON.stringify(this.htmlSection))
          htmlSection.text = this.html
          this.content.push(htmlSection)
          this.editContent()
        }
      }
    }

  },
  mounted() {
    this.initContent()
  }
}
</script>

<style scoped>

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.addButtonsStyle {
  z-index:999;
  position: absolute;
  bottom : -30px ;
  right: 0;
  left: 0 ;
  margin-left : auto;
  margin-right: auto
}
.relative {
  position : relative
}

[contenteditable=true]:empty:not(:focus):before{
  content:attr(data-ph);
}
</style>